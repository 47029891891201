<!--begin::Brand-->
<div class="brand flex-column-auto" id="kt_brand" [ngClass]="brandClasses">
  <!--begin::Logo-->
  <a routerLink="/dashboard" class="brand-logo">
    <img alt="Logo" [attr.src]="headerLogo" style="width:100%"/>
  </a>
  <!--end::Logo-->

  <ng-container *ngIf="asideSelfMinimizeToggle">
    <button class="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
      <span
        [inlineSVG]="
          './assets/media/svg/icons/Navigation/Angle-double-left.svg'
        "
        cacheSVG="true"
        class="svg-icon svg-icon-xl"
      ></span>
    </button>
  </ng-container>
</div>
<!--end::Brand-->

<!--begin::Aside Menu-->
<div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
  <ng-container *ngIf="disableAsideSelfDisplay">
    <!--begin::Header Logo-->
    <a routerLink="/">
      <img alt="Logo" [attr.src]="headerLogo"/>
    </a>
    <!--end::Header Logo-->
  </ng-container>
  <!--begin::Menu Container-->
  <div
    id="kt_aside_menu"
    class="aside-menu"
    [ngClass]="asideMenuCSSClasses"
    data-menu-vertical="1"
    [attr.data-menu-scroll]="asideMenuScroll"
    [attr.data-menu-dropdown]="asideMenuDropdown"
    data-menu-dropdown-timeout="500"
  >
    <!-- begin::Menu Nav -->
    <ul class="menu-nav" [ngClass]="ulCSSClasses">
      <!-- begin::1 Level -->
      <li
        class="menu-item"
        aria-haspopup="true"
        routerLinkActive="menu-item-active"
      >
        <a class="menu-link" routerLink="/dashboard" routerLinkActive="active">
          <span
            [inlineSVG]="'./assets/media/svg/icons/Design/Layers.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
      <!-- end::1 Level -->

      <!-- begin::1 Level -->
      <li
        class="menu-item"
        aria-haspopup="true"
        routerLinkActive="menu-item-active"
        *ngIf="isDataWareHouse"
      >
        <a class="menu-link"
           routerLink="/data-ware-house"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Home/Library.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Data Warehouse</span>
        </a>
      </li>
      <li
        class="menu-item"
        aria-haspopup="true"
        routerLinkActive="menu-item-active"
        *ngIf="isDataPipeline"
      >
        <a
          class="menu-link"
          routerLink="/data-pipeline"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Layout/Layout-3d.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Data Pipeline</span>
        </a>
      </li>
      <li
        class="menu-item"
        aria-haspopup="true"
        routerLinkActive="menu-item-active"
        *ngIf="isSmartQuery"
      >
        <a
          class="menu-link"
          routerLink="/smart-query"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Smart Query</span>
        </a>
      </li>

      <li
        class="menu-item"
        aria-haspopup="true"
        routerLinkActive="menu-item-active"
        *ngIf="isDataComparator"
      >
        <a
          class="menu-link"
          routerLink="/data-comparator"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Code/balance-scale.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Data Comparator</span>
        </a>
      </li>

<!--      <li-->
<!--        class="menu-item"-->
<!--        aria-haspopup="true"-->
<!--        routerLinkActive="menu-item-active"-->
<!--        *ngIf="isSmartQuery"-->
<!--      >-->
<!--        <a-->
<!--          class="menu-link"-->
<!--          routerLink="/smart-query-V2"-->

<!--        >-->
<!--          <span-->
<!--            [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'"-->
<!--            cacheSVG="true"-->
<!--            class="svg-icon menu-icon"-->
<!--          ></span>-->
<!--          <span class="menu-text">Smart Query-2</span>-->
<!--        </a>-->
<!--      </li>-->

<!--      <li-->
<!--        class="menu-item"-->
<!--        aria-haspopup="true"-->
<!--        routerLinkActive="menu-item-active"-->
<!--        *ngIf="isSmartQuery"-->
<!--      >-->
<!--        <a-->
<!--          class="menu-link"-->
<!--          routerLink="/smart-query-V3"-->

<!--        >-->
<!--          <span-->
<!--            [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'"-->
<!--            cacheSVG="true"-->
<!--            class="svg-icon menu-icon"-->
<!--          ></span>-->
<!--          <span class="menu-text">Smart Query-3</span>-->
<!--        </a>-->
<!--      </li>-->


      <!-- <li class="menu-item"
          aria-haspopup="true"
          routerLinkActive="menu-item-active"
          *ngIf="isSmartSearch"
      >
        <a
          class="menu-link"
          routerLink="/smart-search"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Communication/Address-card.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Smart Search</span>
        </a>
      </li> -->

<!--      <hr style="margin: 5px">-->

      <li class="menu-item"
          aria-haspopup="true"
          routerLinkActive="menu-item-active"
          *ngIf="isCrawler"
      >
        <a
          class="menu-link"
          routerLink="/crawler"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Code/Crawler.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Website Crawler</span>
        </a>
      </li>



      <li class="menu-item"
          aria-haspopup="true"
          routerLinkActive="menu-item-active"
          *ngIf="isContentHub"
      >
        <a
          class="menu-link"
          routerLink="/content-hub"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Files/File-plus.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Content Hub</span>
        </a>
      </li>
      <li class="menu-item"
          aria-haspopup="true"
          routerLinkActive="menu-item-active"
          *ngIf="isChatbotCustomization"
      >
        <a
          class="menu-link"
          routerLink="/llm-model"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Code/Puzzle.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Chatbot Customization</span>
        </a>
      </li>

      <li class="menu-item"
          aria-haspopup="true"
          routerLinkActive="menu-item-active"
          *ngIf="isChatbot"
      >
        <a
          class="menu-link"
          routerLink="/chatbot"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Communication/Chat2.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Chatbot</span>
        </a>
      </li>

      <hr style="margin: 5px">

      <li
        class="menu-item"
        routerLinkActive="menu-item-active"
        aria-haspopup="true"
        data-menu-toggle="hover"
        *ngIf="isUserGroup && (userRole === enumUserRole.SystemAdmin || userRole === enumUserRole.OrganizationAdmin || userRole === enumUserRole.Manager)"
      >
        <a
          class="menu-link"
          routerLink="/user-group"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/Communication/Group.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">User & Group</span>
        </a>
      </li>
      <li
        class="menu-item"
        routerLinkActive="menu-item-active"
        aria-haspopup="true"
        data-menu-toggle="hover"
        *ngIf="isAccessControl && (userRole === enumUserRole.SystemAdmin || userRole === enumUserRole.OrganizationAdmin)"
      >
        <a
          class="menu-link"
          routerLink="/access-control"
        >
    <span
      [inlineSVG]="'./assets/media/svg/icons/General/User.svg'"
      cacheSVG="true"
      class="svg-icon menu-icon"
    ></span>
          <span class="menu-text">Access Control</span>
        </a>
      </li>
      <!--      <li-->
      <!--        class="menu-item"-->
      <!--        aria-haspopup="true"-->
      <!--        routerLinkActive="menu-item-active"-->
      <!--      >-->
      <!--        <a-->
      <!--          class="menu-link"-->
      <!--          routerLink="/role-access"-->
      <!--          routerLinkActive="active"-->
      <!--        >-->
      <!--         <span-->
      <!--           [inlineSVG]="'./assets/media/svg/icons/Communication/Add-user.svg'"-->
      <!--           cacheSVG="true"-->
      <!--           class="svg-icon menu-icon"-->
      <!--         ></span>-->
      <!--          <span class="menu-text">Role Access</span>-->
      <!--        </a>-->
      <!--      </li>-->
      <li
        class="menu-item"
        routerLinkActive="menu-item-active"
        aria-haspopup="true"
        data-menu-toggle="hover"
      >
        <a
          class="menu-link"
          routerLink="/account/my-profile/{{userId}}"
        >
      <span
        [inlineSVG]="'./assets/media/svg/icons/General/Folder.svg'"
        cacheSVG="true"
        class="svg-icon menu-icon"
      ></span>
          <span class="menu-text">My Profile</span>
        </a>
      </li>

      <li class="menu-item"
          routerLinkActive="menu-item-active"
          aria-haspopup="true"
          data-menu-toggle="hover"
          *ngIf="isAdminSetting"
      >
<!--        <a-->
<!--          class="menu-link"-->
<!--          (click)="navigateToAdminSetting()"-->
<!--        >-->
<!--          <span-->
<!--            [inlineSVG]="'./assets/media/svg/icons/General/Settings-2.svg'"-->
<!--            cacheSVG="true"-->
<!--            class="svg-icon menu-icon"-->
<!--          ></span>-->
<!--          <span class="menu-text">Admin Setting</span>-->
<!--        </a>-->
        <a
          class="menu-link"
          routerLink="/admin-setting/smart-search"
          *ngIf="isSmartSearchSetting"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/General/Settings-2.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Admin Setting</span>
        </a>

        <a
          class="menu-link"
          routerLink="/admin-setting/notification"
          *ngIf="!isSmartSearchSetting"
        >
          <span
            [inlineSVG]="'./assets/media/svg/icons/General/Settings-2.svg'"
            cacheSVG="true"
            class="svg-icon menu-icon"
          ></span>
          <span class="menu-text">Admin Setting</span>
        </a>
      </li>

      <li
        class="menu-item"
        routerLinkActive="menu-item-active"
        aria-haspopup="true"
        data-menu-toggle="hover"
      >
        <a
          class="menu-link"
          (click)="logout()"
        >
      <span
        [inlineSVG]="'./assets/media/svg/icons/Navigation/Sign-out.svg'"
        cacheSVG="true"
        class="svg-icon menu-icon"
      ></span>
          <span class="menu-text">Logout</span>
        </a>
      </li>
      <!-- end::1 Level -->
      <li class="menu-item" [ngStyle]="{bottom: '10px', position: 'absolute'}">
        <a class="menu-link">
          <span class="menu-text">@ {{currentYear}} DataCanva <br/>Version: v{{version}}</span>
        </a>
      </li>
    </ul>
    <!-- end::Menu Nav -->
  </div>
  <!--end::Menu Container-->
</div>
<!--end::Aside Menu-->
